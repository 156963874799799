@import '../../../style/variables/colors';
@import '../../../style/variables/fonts';
@import '../../../style/variables/media';

.modal {
    position: relative;
    padding: unset;
    overflow: unset;
    @media screen and (max-width: $mobile-max) {
        margin-top: 2vh;
        max-height: 98vh;
        overflow-y: auto;
    }
}

.list {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 15px;
}

.sectionHeader {
    margin: 0;
    font: 18px/25px $demi-bold;
    color: $black-shark
}

.listRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 25px;
}
.listRowText {
    padding-right: 15px;
    font: 15px/20px $regular;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.textOverflow {
    overflow: hidden;
    text-overflow: ellipsis;
}

.contentBody {
    margin: 5px 10px 100px 30px;
    min-height: 40vh;
    white-space: pre-wrap;
    font: $font-medium $regular;
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-radius: 10px;
    padding: 25px 0;
    background-color: $white;
}

.stickySection {
    max-height: 80vh;
    overflow: auto;
    padding-top: 80px;
}
