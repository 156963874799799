@import '../../style/variables/colors';
@import '../../style/variables/fonts';
@import '../../style/variables/media';

.modal {
    position: relative;
    padding: unset;
    overflow: unset;
    @media screen and (max-width: $mobile-max) {
        margin-top: 2vh;
        max-height: 98vh;
        overflow-y: auto;
    }
}

.sectionHeader {
    margin: 0;
    font: 18px/25px $demi-bold;
    color: $black-shark
}

.contentBody {
    margin: 5px 10px 100px 30px;
    min-height: 40vh;
    white-space: pre-wrap;
    font: $font-medium $regular;
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-radius: 10px;
    padding: 25px 0;
    background-color: $white;
}

.stickySection {
    max-height: 80vh;
    overflow: auto;
    padding-top: 80px;
}
