@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';
@import '../../style/variables/media.scss';

.subHeader {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 10px auto;
    max-width: 1194px;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
.onlyButtons {
    composes: subHeader;
    justify-content: flex-end;
}
.buttonContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
