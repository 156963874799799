@import '~commons/src/style/variables/colors.scss';
@import '~commons/src/style/variables/fonts.scss';

.checkboxLabel {
    display: flex;
    color: $black-shark !important;
    font: $font-medium $regular !important;
    -webkit-font-smoothing: initial;
}

.container {
    display: flex;
    position: relative;
}
