@import './variables/colors.scss';
@import './variables/fonts.scss';

.input {
    &__slider {
        display: flex;
        align-items: center;
        &__wrapper {
            display: flex;
            max-width: 300px;
            width: 100%;
        }
        &__line {
            color: $blue-azul !important;
            padding: 40px 0 !important;
        }
        &__value-label {
            background: transparent !important;
            > span {
                > span {
                    color: $black-shark;
                }
            }
        }
    }
    &__timer {
        display: flex;
        align-items: center;
        &__input {
            width: 80px;
            margin-left: 30px;
            margin-right: 10px;
        }
        &__header {
            margin-bottom: unset;
        }
    }
    &__radio-selector {
        &__icon {
            display: flex;
            align-items: center;
            svg {
                height: 22px;
                width: 22px;
            }
        }
        &__radio {
            margin: 15px 0;
        }
    }
}
