@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';
@import '../../style/variables/media.scss';

.header {
    margin-top: 11px;
    z-index: 1;
    display: flex;
    @media screen and (max-width: $sidebar-disappear) {
        padding-top: 0;
        padding-left: 0;
        min-height: 70px;
        display: flex;
        align-items: center;
    }
}

.headerText {
    margin: 0;
    @media screen and (max-width: $sidebar-disappear) {
        margin-top: 0;
        margin-bottom: 7px;
    }
}

.editButton {
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
    width: 100%;
}

.editContent {
    display: flex;
    align-items: center;
    max-width: calc(95% - 90px);
    @media screen and (max-width: $sidebar-disappear) {
        max-width: 95%;
    }
    h1 {
        text-align: left;
        word-break: break-word;
    }
}

.icon {
    color: $oslo-gray;
    margin-left: 5px;
    font-size: 20px;
}

.editContainer {
    display: flex;
}

.editInput {
    font-size: 29px;
    font-family: $demi-bold;
    color: $grey-shuttle;
}
