@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';
@import '../../style/variables/media.scss';

.dropdownHeader {
    display: flex;
    align-items: center;
}

.endIcon {
    margin-left: 5px;
}

.pageTabs {
    color: $black-shark;
    height: 50px;
    background-color: white;
    position: relative;
    padding-left: 90px;
}

.menuModal {
    position: absolute;
    background-color: rgba(41, 34, 34, 0.52);
    margin-top: 50px;
    height: 100%;
    width: 100%;
    transition: 500ms;
    z-index: 10;
}
