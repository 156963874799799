@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';


.wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.header {
    font: $font-large $demi-bold;
    margin-bottom: 5px;
}

.description {
    font: $font-small $regular;
    margin-bottom: 20px;
}

.button {
    display: flex;
    margin-bottom: 20px;
}


