@import '../../style/variables/colors';
@import '../../style/variables/fonts';
@import '../../style/variables/media';

.sectionLine {
    width: 100%;
    border-bottom: 1px solid $grey-ash;
    margin: 10px auto 20px -10px;
}

.list {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 15px;
}

.sectionHeader {
    margin: 0;
    font: 18px/25px $demi-bold;
    color: $black-shark
}

.listRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 25px;
}
 .listRowText {
     padding-right: 15px;
     font: 15px/20px $regular;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
 }
.textOverflow {
    overflow: hidden;
    text-overflow: ellipsis;
}
