@import '../../style/variables/colors';
@import '../../style/variables/fonts';
@import '../../style/variables/media';

.menuIcon {
    background: transparent;
    width: 18px;
    height: 18px;
    position: relative;
    top: -2px;
    cursor: pointer;
    border: none;
}
.menuIconLine {
    height: 3px;
    width: 100%;
    background-color: $black-shark;
    display: block;
    border-radius: 9px;
    opacity: 1;
    -webkit-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    color: $black-shark;
}

.firstLine {
    composes: menuIconLine;
    -webkit-transform: translateY(9px);
    transform: translateY(9px);
}
.firstLineOpen {
    top: 18px;
    width: 0;
    left: 50%;
}
.secondLine {
    composes: menuIconLine;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}
.secondLineOpen {
    -webkit-transform: rotate(45deg) translateY(4px) translateX(4.5px);
    transform: rotate(45deg) translateY(4px) translateX(4.5px);
}

.thirdLine {
    composes: menuIconLine;
    -webkit-transform: translateY(9px);
    transform: translateY(9px);
}
.thirdLineOpen {
    -webkit-transform: rotate(-45deg) translateY(2px) translateX(-2px);
    transform: rotate(-45deg) translateY(2px) translateX(-2px);
}

.wrapper {
    z-index: 501;
    background: transparent;
    width: 33px;
    height: 33px;
    padding: 5px;
    border: unset;
    &:focus {
        outline: unset;
        span {
            background: $blue-aura;
        }
    }
}
