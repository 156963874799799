@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';
@import '../../style/variables/media.scss';


.banner {
    background-color: $red-auburn;
    position: relative;
    text-align: center;
    width: 100%;
    height: 45px;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: $mobile-max) {
        height: 65px;
    }
}

.message {
    color: $white;
    font: $font-medium $regular;

}
.link {
    composes: message;
    font-family: $demi-bold;
    text-decoration: underline;
}
