@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';

.wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    overflow-x: auto;
}

.labelSection {
    height: 200px;
}
.blueSection {
    composes: labelSection;
    background: $blue-aura;
    min-width: 148px;
    display: flex;
    flex-direction: column;
}

.whiteSection {
    composes: labelSection;
    background: $white;
    min-width: 230px;
    padding: 16px 5px 18px 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.airthingsLogo {
    width: 97px;
    fill: $white;
    margin: 10px 0 0 0;
    padding-left: 16px;
    padding-bottom: 16px;
    margin-top: auto;
}
.organizationLogo {
    max-height: 30px;
    max-width: 100%;
}
.headerSection {
    padding-top: 16px;
    padding-left: 16px;
    word-wrap: break-word;
}

.header {
    font-family: $demi-bold;
    color: $white;
    font-size: 18px;
    width: 111px;
}
.subHeader {
    font-family: $regular;
    color: $white;
    font-size: 12px;
    width: 111px;
    padding-top: 7px;
}
.organizationName {
    font-family: $medium;
    color: $black-shark;
    font-size: 19px;
    width: 100%;
    -webkit-line-clamp: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.qrLabel {
    font-family: $regular;
    color: $black-shark;
    font-size: 8px;
}
