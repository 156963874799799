@import '../../style/variables/colors.scss';
@import '../../style/variables/fonts.scss';

.label {
    display: flex;
    align-items: center;
}

.wrapper {
    display: flex;
    max-width: 300px;
    width: 100%;
    margin: 10px 0 40px 0;
}

