@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';

.wrapper {
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.noMarginWrapper {
    composes: wrapper;
    margin: unset;
}

.icon {
    padding: 0 18px 0 10px;
}
.info {
    display: flex;
    flex-direction: column;
}
