@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';
@import '../../style/variables/media.scss';


.banner {
    background-color: $blue-azul;
    position: relative;
    text-align: center;
    width: 100%;
    height: 45px;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: $mobile-max) {
        height: 65px;
    }
}

    .text {
        line-height: 23px;
        color: $white;
        font-size: 14px;
        font-family: $ultra-thin;
    }
    .link {
        composes: text;
        margin-left: 10px;
        text-decoration: underline;
    }
 .message {
     composes: text;
     @media screen and (max-width: $mobile-max) {
        line-height: 18px;
    }
}
