@import './variables/colors.scss';
@import './variables/fonts.scss';

.wizard {
    &__device-module {
        width: 100%;
        display: block !important;
        max-width: 398px;
        min-height: 460px;
        &--border-bottom {
            border-bottom: 1px solid $grey-ash;
        }
        &--border-right {
            border-right: 1px solid $grey-ash;
        }
        &__section {
            width: 244px;
            display: flex;
            justify-content: center;
            margin: 15px auto;
            &--block {
                display: block;
            }
            &--center {
                min-height: 430px;
                justify-content: center;
                align-items: center;
            }
            &__icon {
                height: 60px;
                width: 60px;
                margin: 20px auto;
            }
            &__buttons {
                justify-content: space-between;
                align-items: center;
                flex-direction: column;
                width: 100%;
                .btn {
                    margin-bottom: 20px;
                    width: 100%;

                }
                &__text {
                    font: 12px/16px $regular;
                    color: $black-shark;
                    margin: auto;
                    width: 100%;
                    max-width: 200px;
                    text-align: center;
                    &--disabled {
                        color: $oslo-gray;
                    }
                    &--no-margin {
                        display: flex;
                        align-items: flex-end;
                        margin: 0;
                    }
                }
            }
        }
        &__section-finish {
            width: 100%;
            flex-wrap: wrap;
            margin: 15px auto;
            max-width: 380px;
            display: flex !important;
            justify-content: center;
            &__icon {
                height: 43px;
                margin: 20px;
            }
            &__header {
                font: 24px $medium;
                margin: 15px auto 5px auto;
            }
        }
        &__section-text {
            width: 100%;
            display: block;
            max-width: 450px;
            text-align: center;
            margin: auto;
            font-size: 13px;
        }
        &__form {
            width: 100%;
            .form__attr--input {
                margin-bottom: 10px;
                &#checkCode {
                    text-transform: uppercase;
                }
            }
            &__edit {
                display: flex;
                justify-content: space-between;
                margin-top: 50px;
                > button {
                    min-width: 110px;
                }
            }
            &__header {
                font-family: $bold;
                font-size: 18px;
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }
    }
    &__button-footer {
        margin: 37px auto 18px auto;
        justify-content: center;
        display: flex !important;
        &--block {
            display: block !important;
        }
        .btn {
            margin: 0 auto 20px auto;
        }
        #submit {
            min-width: 115px;
        }
        #linkToDevices {
            margin-left: 15px;
        }
    }
    &__border-box {
        border-bottom: 1px solid $grey-ash;
    }
}
