@import './variables/colors.scss';
@import './variables/fonts.scss';
.add-module {
    &__tile {
        border: 1px solid $grey-ash;
        background-color: $grey-porcelain;
        text-align: center;
        &--blurred {
            filter: blur(3px);
        }
        &__blur {
            position: absolute;
            z-index: 1;
            width: 250px;
            margin: 90px 59px 0 59px;
            font-family: $demi-bold;
            color: $black-shark;
            font-size: 18px;
            &__text {
                background: $white;
                padding: 2px 0;
            }
        }
        &__title {
            height: 25px;
            color: $grey-shuttle;
            font-family: $demi-bold;
            font-size: 18px;
            font-weight: 900;
            line-height: 25px;
            margin: 0 0 15px 12px;
        }
        &__image {
            height: 130px;
            margin: 0 auto;
        }
        &__button {
            width: 135px;
            margin: 5px auto 0 auto;
            cursor: pointer;
        }
    }
    &__title {
        text-align: center;
        font-family: $bold;
        font-size: 19px;
        font-weight: 900;
        line-height: 25px;
        color: $grey-shuttle;
        margin: 0 0 0 24px;
    }
}
