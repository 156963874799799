@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';
@import '../../style/variables/media.scss';


.banner {
    background-color: $black-shark;
    position: relative;
    text-align: center;
    width: 100%;
    height: 45px;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $mobile-max) {
        height: 65px;
    }
}

.text {
    line-height: 23px;
    color: $white;
    font-size: 14px;
    font-family: $ultra-thin;

}
.link {
    composes: text;
    margin-left: 10px;
    text-decoration: underline;
}
.message {
    composes: text;
    @media screen and (max-width: $mobile-max) {
        line-height: 18px;
    }
}

.button {
    line-height: 24px;
    margin-left: 10px;
    padding: 0 8px;
    background-color: rgba(255, 255, 255, 0.6);
    border: none;
    border-radius: 3px;
    box-shadow: none;
    font-size: 12px;
    font-weight: 500;
    color: $black-shark;
    cursor: pointer;
}
