@import '~commons/src/style/variables/media';
@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';

.partner-portal {
    &__arrow-right {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-end;
    }

    &__status-customer-table {
        &__action-buttons {
            display: flex;
            align-items: center;
            width: 150px;
            justify-content: flex-end;

            button:not(:last-child) {
                margin-right: 12.5px;
            }
        }
    }

    &__status-customer-mobile-table {
        &__action-buttons {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            button:not(:last-child) {
                margin-right: 12.5px;
            }
        }
    }

    &__customer-info {
        margin-top: 30px;
        display: flex;

        &__section {
            width: 50%;
        }

        @media screen and (max-width: $mobile-break) {
            display: block;
            &__section {
                width: unset;
            }
        }

        .settings-details-container {
            padding: unset;
        }

        &__button-row {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            align-items: center;
        }
    }

    &__device-status {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @media screen and (max-width: $mobile-break) {
            flex-wrap: wrap;
        }

        &__devices {
            display: flex;
            align-items: center;
            width: 140px;
        }

        &__total-number {
            margin-left: 12px;
        }

        &__ratings {
            display: flex;
            justify-content: space-evenly;

            &__rating-wrapper {
                display: flex;
                align-items: center;
            }

            &__rating {
                .value {
                    margin-left: 4px;
                }
            }
        }
    }

    &__pending-member {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__row {
            display: flex;
            justify-content: space-between;
            padding-bottom: 30px;

            &__header {
                min-height: 19px;
                color: $oslo-gray;
                text-align: left;
                font: 14px/19px $regular;
                display: flex;
                align-items: center;
            }
        }
    }
}
