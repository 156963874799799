@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';
@import '../../style/variables/media.scss';


.headerWrapper {
    margin-bottom: 30px;
}
.header {
    background-color: white;
    color: $black-shark;
    min-height: 110px;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 90px;
    padding-top: 11px;
    z-index: 1;
    width: 100%;
    @media screen and (max-width: $sidebar-disappear) {
        padding-top: 0;
        min-height: 70px;
        padding-left: 30px;
    }
}
.headerWithSubText {
    composes: header;
    padding-top: unset;
}

.namePadded {
    padding-top: 27px;
    @media screen and (max-width: $sidebar-disappear) {
        padding-top: 20px;
    }
}

.backLink {
    font: 15px/20px $regular;
    color: $grey-shuttle;
    text-decoration: none;
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    cursor: pointer;
    margin-left: 0;
}

.headerText {
    margin: 0;
    word-break: break-word;
    @media screen and (max-width: $sidebar-disappear) {
            margin-top: 0;
            margin-bottom: 7px;
    }
}
