@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';
@import '../../style/variables/media.scss';

.wrapper {
    display: flex;
    justify-content: space-between;
}

.header {
    margin-top: 0;
}

.subHeader {
    font: $font-medium $regular;
}
