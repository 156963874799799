@import './variables/colors.scss';
@import './variables/media.scss';

.info-list-tile {
    min-height: 90px;
    background-color: $white;
    border-radius: 10px;
    display: flex;
    padding: 15px 30px;
    align-items: center;
    justify-content: space-between;

    &--with-margin {
        margin-top: 20px;
    }

    @media screen and (max-width: $mobile-max) {
        display: block;
    }

    &__text {
        padding: 10px 10px 10px 0;
        white-space: pre-wrap;
    }
}
