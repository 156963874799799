@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';
@import '../../style/variables/media.scss';


.header {
    display: flex !important;
    border-bottom: 1px solid $grey-ash;
    justify-content: space-between;
    width: 100%;
    padding: 0 25px;
    height: 69px;
}
.button {
    cursor: pointer;
}
.text {
    color: $black-shark;
    font-size: 20px;
    margin: auto 0;
}
.inactiveText {
    composes: text;
    color: $oslo-gray;
}
.summary {
    composes: text;
    font-size: 14px;
    margin: auto 0;
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.sectionClosed {
    border-bottom: none;
}
